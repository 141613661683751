<template>
  <div class="container">
    <div
    class="logo"></div>
    <router-link
    v-on:click.native="handleJump"
    class="jump-item addBorder" to="/broker">
      <img class="avatar-icon" src="@/assets/icon/Avatar.png" alt="" />
      Dashboard
      <img class=" hidden sm:inline-block setting-icon" src="@/assets/icon/setting.png" alt="" />
    </router-link>
    <div class="menu-link">
      <router-link
        v-show="getBrokerAvaliable"
        v-on:click.native="handleJump"
        class="jump-item" to="/broker/promotion-link"
      >
        Promotion Link
      </router-link>
      <router-link
        v-show="getBrokerAvaliable"
        v-on:click.native="handleJump"
        class="jump-item" to="/broker/customers"
      >
        Customers
      </router-link>
      <router-link
        v-show="getBrokerAvaliable"
        v-on:click.native="handleJump"
        class="jump-item" to="/broker/commission"
      >
        Commission
      </router-link>
      <router-link
        v-on:click.native="handleJump"
        class="jump-item" to="/broker/live-rate"
      >
        Live Rate
      </router-link>
      <router-link
        v-show="getBrokerAvaliable"
        v-on:click.native="handleJump"
        class="jump-item" to="/broker/settings"
      >
        Settings
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('login', ['user']),
    ...mapState(['isMobile']),
    ...mapGetters('broker', ['getBrokerAvaliable'])
  },
  methods: {
    handleJump () {
      if (this.isMobile) {
        this.$emit('leftMenuHide')
      }
    }
  }
}
</script>

<style scoped>
.container {
  flex-shrink: 0;
  width: 300px;
  box-sizing: border-box;
  min-height: 100vh;
  border: 1px solid black;
  background: #163257;
  z-index: 10;
}
.avatar-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.setting-icon {
  width: 21px;
  height: 21px;
  line-height: 60px;
  right: 25px;
  top: 21px;
  position: absolute;
}
.logo {
  display: block;
  height: 80px;
  width: 100%;
  background: url("../../assets/img/wutong_logo.jpg") no-repeat center center / 25%;
}
.jump-item {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;
  height: 60px;
  text-decoration: none;
  line-height: 48px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.menu-link .router-link-exact-active {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.menu-link .router-link-exact-active::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0px;
  width: 4px;
  height: 100%;
  background: #bc9a41ff;
}
.add-border {
  border-bottom: 1px solid black;
}
@media only screen and (max-width: 600px) {
.container {
  flex-shrink: 0;
  width: 45vw;
  box-sizing: border-box;
  min-height: 100vh;
  border: 0px solid black;
  background: #163257;
}
.avatar-icon {
  width: 8vw;
  height: 8vw;
  margin-right: 5vw;
}
.settingIcon {
  width: 21px;
  height: 21px;
  right: 12%;
  top: 21px;
  position: absolute;
}
.logo {
  display: block;
  height: 10vh;
  width: auto;
  background: url("../../assets/img/wutong_logo.jpg") no-repeat center center/ 18vw;
}
.avatarIcon {
  width: 8vw;
  height: 8vw;
  margin-right: 5vw;
}
.jump-item {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;
  height: 7vh;
  text-decoration: none;
  font-weight: 400;
  justify-content: flex-start;
  font-size: 16px;
  color: #ffffff;
}
.menu-link{
  margin-top:  1vh;
}
.menu-link .router-link-exact-active {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.menu-link .router-link-exact-active::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0px;
  width: 4px;
  height: 100%;
  background: #bc9a41ff;
}
.addBorder {
  border-bottom: 0px solid black;
}
}
</style>
